<template>
  <div id="ethyleneOxideReport">
    <el-dialog
      :title="ethyleneOxideReportFormTitle"
      width="900px"
      :visible.sync="ethyleneOxideReportDialogVisible"
      :close-on-click-modal="false"
      @close="ethyleneOxideReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="ethyleneOxideReportFormRef"
        :model="ethyleneOxideReportForm"
        :rules="ethyleneOxideReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="ethyleneOxideReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="ethyleneOxideReportForm.productName"
                placeholder="请输入产品名称"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品型号" prop="spec">
              <el-input
                v-model="ethyleneOxideReportForm.spec"
                placeholder="请输入产品型号"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产批号" prop="productionBatchNo">
              <el-input
                v-model="ethyleneOxideReportForm.productionBatchNo"
                placeholder="请输入生产批号"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="灭菌批号" prop="sterilizeBatchNo">
              <el-input
                v-model="ethyleneOxideReportForm.sterilizeBatchNo"
                placeholder="请输入灭菌批号"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批量" prop="batch">
              <el-input
                v-model="ethyleneOxideReportForm.batch"
                placeholder="请输入批量"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="取样量" prop="sampleAmount">
              <el-input
                v-model="ethyleneOxideReportForm.sampleAmount"
                placeholder="请输入取样量"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验依据" prop="basis">
              <el-input
                v-model="ethyleneOxideReportForm.basis"
                placeholder="请输入检验依据"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker
                v-model="ethyleneOxideReportForm.inspectionDate"
                placeholder="请选择检验日期"
                value-format="yyyy-MM-dd"
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="ethyleneOxideReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检验标准">
              环氧乙烷相对含量≤10mg/kg（即≤10μg/g）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测步骤">
              1、供试液制备：<br>
              取样品上有代表性的部位将试样剪成5mm碎块，称取重量2.0g置于容器中，加10ml的0.1mol/L盐酸，室温放置1h，作为供试液。<br>
              2.标准液的制备：<br>
              取5支纳氏比色管，分别加0.1mol/L盐酸2ml,再精确加入0.5ml,1.0ml,1.5ml,2.0ml,2.5ml乙二醇标准溶液，另取一支加0.1mol/L盐酸2ml，作空白对照。上述各管中分别加5g/L高碘酸溶液0.4ml，摇匀放置1h。然后分别滴加10g/L硫代硫酸钠溶液至黄色恰好消失。再分别加入品红-亚硫酸溶液0.2ml，用蒸馏水稀释至10ml，35—37℃放置1h，于560nm波长处以空白液作参比，测定吸光度，绘制标准曲线，结果记录下表1：
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="表1">
          <vxe-table
            ref="xTable1"
            border
            resizable
            show-overflow
            keep-source
            :data="detail1List"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="itemNo"
              title="编号"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="itemNo1"
              title="1"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="itemNo2"
              title="2"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="itemNo3"
              title="3"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="itemNo4"
              title="4"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="itemNo5"
              title="5"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
          </vxe-table>
        </el-form-item>
        <el-form-item label="标准曲线作图">
          <vxe-toolbar>
            <template #buttons>
              <vxe-button @click="generateEcharts()">
                生成
              </vxe-button>
            </template>
          </vxe-toolbar>
          <div id="echarts" style="height:400px;" />
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              精确移取试验液2.0ml于纳氏管中，按上述步骤操作，测定吸光度，由标准曲线查得相应体积。<br>
              环氧乙烷相对含量计算公式：CEO=1.775VC11000<br>
              CEO---- 单位产品环氧乙烷相对含量，μg/g<br>
              C1---- 乙二醇标准溶液浓度，g/L<br>
              V---- 标准曲线上查的试液相应体积，ml<br>
              结果记录下表2：
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="表2">
          <vxe-toolbar
            v-if="ethyleneOxideReportFormTitle === '新增环氧乙烷残留量检验报告'
              || ethyleneOxideReportFormTitle === '修改环氧乙烷残留量检验报告'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable2"
            border
            resizable
            show-overflow
            keep-source
            :data="detail2List"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column type="seq" title="样品序号" width="80" />
            <vxe-table-column
              field="absorbance"
              title="实验测得的吸光度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="volume"
              title="相应体积（ml）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="content"
              title="CEO（μg/g）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="结果判定"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="ethyleneOxideReportFormTitle === '新增环氧乙烷残留量检验报告'
                || ethyleneOxideReportFormTitle === '修改环氧乙烷残留量检验报告'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="最终结论" prop="finalConclusion">
              <el-input
                v-model="ethyleneOxideReportForm.finalConclusion"
                placeholder="请输入最终结论"
                clearable
                :disabled="ethyleneOxideReportFormTitle !== '新增环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '修改环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="ethyleneOxideReportForm.reviewResult"
                :disabled="ethyleneOxideReportFormTitle !== '复核环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="ethyleneOxideReportForm.approvalResult"
                :disabled="ethyleneOxideReportFormTitle !== '批准环氧乙烷残留量检验报告'
                  && ethyleneOxideReportFormTitle !== '环氧乙烷残留量检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="ethyleneOxideReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="ethyleneOxideReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="ethyleneOxideReportFormTitle === '环氧乙烷残留量检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="灭菌批号">
        <el-input v-model="searchForm.sterilizeBatchNo" placeholder="请输入灭菌批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['EPOXY_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="ethyleneOxideReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="产品型号" />
      <el-table-column prop="productionBatchNo" label="生产批号" />
      <el-table-column prop="sterilizeBatchNo" label="灭菌批号" />
      <el-table-column prop="batch" label="批量" />
      <el-table-column prop="sampleAmount" label="取样量" />
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column label="检验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="finalConclusion" label="最终结论" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate1">{{ scope.row.inspectionDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['EPOXY_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['EPOXY_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['EPOXY_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['EPOXY_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="ethyleneOxideReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addEthyleneOxideReport,
  deleteEthyleneOxideReport,
  updateEthyleneOxideReport,
  selectEthyleneOxideReportInfo,
  selectEthyleneOxideReportList,
  reviewEthyleneOxideReport,
  approvalEthyleneOxideReport
} from '@/api/quality/ethyleneOxideReport'
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      ethyleneOxideReportDialogVisible: false,
      ethyleneOxideReportFormTitle: '',
      ethyleneOxideReportForm: {
        id: '',
        formNo: '',
        productName: '',
        spec: '',
        productionBatchNo: '',
        sterilizeBatchNo: '',
        batch: '',
        sampleAmount: '',
        basis: '',
        inspectionDate: '',
        reportDate: '',
        finalConclusion: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        oxideDetail1Json: '',
        oxideDetail2Json: '',
        taskId: ''
      },
      ethyleneOxideReportFormRules: {
        sterilizeBatchNo: [{ required: true, message: '灭菌批号不能为空', trigger: ['blur', 'change']}]
      },
      ethyleneOxideReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        sterilizeBatchNo: ''
      },
      detail1List: [
        { itemNo: '体积' },
        { itemNo: '吸光度' }
      ],
      detail2List: []
    }
  },
  created () {
    selectEthyleneOxideReportList(this.searchForm).then(res => {
      this.ethyleneOxideReportPage = res
    })
  },
  methods: {
    ethyleneOxideReportDialogClose () {
      this.$refs.ethyleneOxideReportFormRef.resetFields()
      this.detail1List = [
        { itemNo: '体积' },
        { itemNo: '吸光度' }
      ],
      this.detail2List = []
      let e = echarts.getInstanceByDom(document.getElementById('echarts'))
      if (e) {
        e.clear()
      }
    },
    ethyleneOxideReportFormSubmit () {
      if (this.ethyleneOxideReportFormTitle === '环氧乙烷残留量检验报告详情') {
        this.ethyleneOxideReportDialogVisible = false
        return
      }
      this.$refs.ethyleneOxideReportFormRef.validate(async valid => {
        if (valid) {
          if (this.ethyleneOxideReportFormTitle === '新增环氧乙烷残留量检验报告') {
            this.ethyleneOxideReportForm.id = ''
            this.ethyleneOxideReportForm.status = 1
            this.ethyleneOxideReportForm.oxideDetail1Json = JSON.stringify(this.$refs.xTable1.getTableData().tableData)
            this.ethyleneOxideReportForm.oxideDetail2Json = JSON.stringify(this.$refs.xTable2.getTableData().tableData)
            await addEthyleneOxideReport(this.ethyleneOxideReportForm)
          } else if (this.ethyleneOxideReportFormTitle === '修改环氧乙烷残留量检验报告') {
            this.ethyleneOxideReportForm.oxideDetail1Json = JSON.stringify(this.$refs.xTable1.getTableData().tableData)
            this.ethyleneOxideReportForm.oxideDetail2Json = JSON.stringify(this.$refs.xTable2.getTableData().tableData)
            await updateEthyleneOxideReport(this.ethyleneOxideReportForm)
          } else if (this.ethyleneOxideReportFormTitle === '复核环氧乙烷残留量检验报告') {
            this.ethyleneOxideReportForm.status = 2
            await reviewEthyleneOxideReport(this.ethyleneOxideReportForm)
          } else if (this.ethyleneOxideReportFormTitle === '批准环氧乙烷残留量检验报告') {
            this.ethyleneOxideReportForm.status = 3
            await approvalEthyleneOxideReport(this.ethyleneOxideReportForm)
          }
          this.ethyleneOxideReportPage = await selectEthyleneOxideReportList(this.searchForm)
          this.ethyleneOxideReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.ethyleneOxideReportFormTitle = '新增环氧乙烷残留量检验报告'
      this.ethyleneOxideReportDialogVisible = true
      this.ethyleneOxideReportForm.formNo = 'HC' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteEthyleneOxideReport(row.id)
        if (this.ethyleneOxideReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.ethyleneOxideReportPage = await selectEthyleneOxideReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.ethyleneOxideReportFormTitle = '修改环氧乙烷残留量检验报告'
      this.ethyleneOxideReportDialogVisible = true
      this.selectEthyleneOxideReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.ethyleneOxideReportFormTitle = '复核环氧乙烷残留量检验报告'
      this.ethyleneOxideReportDialogVisible = true
      this.selectEthyleneOxideReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.ethyleneOxideReportFormTitle = '批准环氧乙烷残留量检验报告'
      this.ethyleneOxideReportDialogVisible = true
      this.selectEthyleneOxideReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.ethyleneOxideReportFormTitle = '环氧乙烷残留量检验报告详情'
      this.ethyleneOxideReportDialogVisible = true
      this.selectEthyleneOxideReportInfoById(row.id)
    },
    selectEthyleneOxideReportInfoById (id) {
      selectEthyleneOxideReportInfo(id).then(res => {
        this.ethyleneOxideReportForm.id = res.id
        this.ethyleneOxideReportForm.formNo = res.formNo
        this.ethyleneOxideReportForm.productName = res.productName
        this.ethyleneOxideReportForm.spec = res.spec
        this.ethyleneOxideReportForm.productionBatchNo = res.productionBatchNo
        this.ethyleneOxideReportForm.sterilizeBatchNo = res.sterilizeBatchNo
        this.ethyleneOxideReportForm.batch = res.batch
        this.ethyleneOxideReportForm.sampleAmount = res.sampleAmount
        this.ethyleneOxideReportForm.basis = res.basis
        this.ethyleneOxideReportForm.inspectionDate = res.inspectionDate
        this.ethyleneOxideReportForm.reportDate = res.reportDate
        this.ethyleneOxideReportForm.finalConclusion = res.finalConclusion
        this.ethyleneOxideReportForm.reviewResult = res.reviewResult
        this.ethyleneOxideReportForm.approvalResult = res.approvalResult
        this.ethyleneOxideReportForm.status = res.status
        this.ethyleneOxideReportForm.taskId = res.taskId
        this.detail1List = res.detail1List
        this.detail2List = res.detail2List
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectEthyleneOxideReportList(this.searchForm).then(res => {
        this.ethyleneOxideReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectEthyleneOxideReportList(this.searchForm).then(res => {
        this.ethyleneOxideReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectEthyleneOxideReportList(this.searchForm).then(res => {
        this.ethyleneOxideReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['EPOXY_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['EPOXY_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable2.insertAt({}, -1)
      await this.$refs.xTable2.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable2.remove(row)
    },
    generateEcharts () {
      const list  = this.$refs.xTable1.getTableData().tableData
      let data = [
        [parseFloat(list[1].itemNo1), parseFloat(list[0].itemNo1)],
        [parseFloat(list[1].itemNo2), parseFloat(list[0].itemNo2)],
        [parseFloat(list[1].itemNo3), parseFloat(list[0].itemNo3)],
        [parseFloat(list[1].itemNo4), parseFloat(list[0].itemNo4)],
        [parseFloat(list[1].itemNo5), parseFloat(list[0].itemNo5)]
      ]
      // let data = [
      //   [0.028, 0.5],
      //   [0.034, 1.0],
      //   [0.048, 1.5],
      //   [0.060, 2.0],
      //   [0.070, 2.5]
      // ]
      let regression = ecStat.regression('linear', data)
      let e = echarts.getInstanceByDom(document.getElementById('echarts'))
      if (!e) {
        e = echarts.init(document.getElementById('echarts'))
      }
      e.setOption({
        title: {
          // text: regression.expression + '   ' + 'R2:',
          text: regression.expression,
          right: 0
        },
        tooltip: {
          formatter: '{c}'
        },
        xAxis: {},
        yAxis: {},
        series: [
          {
            type: 'scatter',
            data: data,
            markLine: {
              // label: {
              //   formatter: regression.expression
              // },
              lineStyle: {
                type: 'solid'
              },
              data: [
                [
                  { coord: regression.points[0], symbol: 'none' },
                  { coord: regression.points[regression.points.length - 1], symbol: 'none' }
                ]
              ]
            }
          }
        ]
      })
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `环氧乙烷残留量检验报告${this.ethyleneOxideReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
