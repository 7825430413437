import axios from '@/common/axios'
import qs from 'qs'

export function addEthyleneOxideReport (data) {
  return axios({
    method: 'post',
    url: '/quality/ethyleneOxide/add',
    data: qs.stringify(data)
  })
}

export function deleteEthyleneOxideReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/ethyleneOxide/delete/' + id
  })
}

export function updateEthyleneOxideReport (data) {
  return axios({
    method: 'put',
    url: '/quality/ethyleneOxide/update',
    data: qs.stringify(data)
  })
}

export function selectEthyleneOxideReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/ethyleneOxide/info/' + id
  })
}

export function selectEthyleneOxideReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/ethyleneOxide/list',
    params: query
  })
}

export function reviewEthyleneOxideReport (data) {
  return axios({
    method: 'put',
    url: '/quality/ethyleneOxide/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalEthyleneOxideReport (data) {
  return axios({
    method: 'put',
    url: '/quality/ethyleneOxide/updateApprove',
    data: qs.stringify(data)
  })
}
